
/* Arabic version */


[dir='rtl'] .left-1\/2 {
    left: 21%;
}
[dir='rtl'].left-2\/3 {
    left: 11%;
}
/* Plain Header section */
header {width: 100%; background: #fff;}
header .inner {height: 80px; width: 1300px; margin: 0 auto; align-items: center;}
.menu {display: flex;}
.navbar {display: flex; flex-direction: row-reverse;}
header .userPanel.desktop {display: flex; align-items:baseline;}
header .userPanel .menu-link {padding:0 0 0 0.9rem;}
header .userPanel .btn {margin: 0 5px; border-radius: 6px; padding: 0.5rem 1rem;}
body[dir='rtl'] header .userPanel .btn {
    min-width: 165px;
    text-align: center;
}
header .userPanel.mobile .btn {    min-width: 122px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;}
    header .userPanel.mobile .menu-language-btn {border: 0;}
header .userPanel .btn.get-quote {background:#55C6EF; border: 0;font-weight: 600;}
.claim-menu-title{font-weight: 400!important;}
.menu-item button{font-weight: 600;}
.postion-line a{font-weight: 600;}

[dir='rtl'] .claim-menu-title{font-weight: 400!important;}
[dir='rtl'] .menu-item button{font-weight: 600;}
[dir='rtl'] .postion-line a{font-weight: 600;}
[dir='rtl'] .menu-language-btn{padding: 0 0.9rem 0 0!important;}
[dir='rtl'] .claimLevel1 li a img{    margin-right: 0;}
/* [dir='rtl'] .childLevel1{margin-right: 0;margin-left: 2rem;} */
[dir='rtl'] .subMenuHead {padding: 0.5rem 1rem 1rem 3rem;}
[dir='rtl'] .claimLevel1 li {padding: 0.3rem 1rem 0.3rem 3rem;}
 .claimLevel1 li {padding:0.5rem 3rem 0.5rem 1rem;}

.claimLevel1 li:hover {background:#F6F8F9; border-radius: 6px;} 
.claimLevel1 li a {font-size: 1rem; font-weight: 400;}
body[dir="rtl"] .claimLevel1 li a {font-weight: 300;}
body[dir="rtl"] .postion-line span {right: auto; left: -8px;}
.level1.life-Insurance-drop-down .subMenuHead {color:#0C4A0D!important ;}

.number{font-family: 'Roboto'!important;font-weight: normal;}
.level1 li:hover img {filter: brightness(0) saturate(100%) invert(24%) sepia(14%) saturate(6934%) hue-rotate(168deg) brightness(95%) contrast(102%);}
.level1 li:hover a {color:#005574;}
#life-Insurance-drop-down-section-0 li:hover img
{filter: brightness(0) saturate(100%) invert(18%) sepia(26%) saturate(3498%) hue-rotate(90deg) brightness(94%) contrast(91%)!important;}
.life-Insurance-drop-down li:hover a {color: #0C4A0D!important;}
.claimLevel1 li a span:first-child {width: 30px;}
.claimLevel1 li a img {width:20px; height:20px; margin-right: 0.5rem;}
.subMenuHead {
    display: flex;
    align-items: center;
    justify-content: start;
    border-bottom: 1px solid #E5E9EB;
    padding: 0.5rem 3rem 1rem 1rem;
    margin-bottom: 0.7rem;
    color: #005574 !important;
    font-size: 1rem;
}
.subMenuHead span {width:30px; }
.claim-dropdown .claimLevel1 {min-width: 170px;}
#life-Insurance-drop-down .subMenuHead {color:#0C4A0D;}
.subMenuHead img {margin: 0 10px 0 0; width: 20px;}
body[dir="rtl"] .subMenuHead img {
    margin: 0 0px 0 0px;
}
body[dir="rtl"] .GeneralInsurance-dropdown .subMenuHead, 
body[dir="rtl"] .GeneralInsurance-dropdown .subMenuHead, body[dir="rtl"] #life-Insurance-drop-down-section-0 .subMenuHead {
    padding: 0.5rem 1rem 1rem 1rem;
}
[dir="rtl"] .GeneralInsurance-dropdown .subMenuHead h3 {
    padding-right: 8px;
}
body[dir="rtl"] #claim-dropdown .subMenuHead {padding: 0 0 0 0;}

button[aria-expanded="true"] {color:#008DC0!important;}
button[aria-expanded="true"] svg path {fill:#008DC0;}
/* .burger {display: none; visibility: hidden} */
.claim-dropdown .childLevel1 {margin: 0!important;}
/* Plain Header section */
@media screen and (min-width: 1295px) {
    /**************** menu css *******************/
    .burger {display: block; visibility:visible}
    .navbar {
        display: flex;
        justify-content: space-between;
   }
    .navbar-block {
        position: initial;
        height: initial;
        opacity: 1;
        overflow: auto;
        pointer-events: visible;
        background: none;
        transition: none;
   }
   .menu {
       padding-block: 1rem;
       padding-inline: auto;
       display: flex;
       flex-direction: row;
       justify-content: flex-end;
       /* column-gap: 1.5rem; */
       height: 37px;
       padding: 0px;
       margin-top: 16px;
  }
   .menu-link {
    color: #252C32;
    text-align: right;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight:500;
    line-height: 40px; /* 192.857% */
       display: flex;
       
       justify-content: space-between;
       padding-block: 0.5rem;
       padding-inline: 1.5rem;
       
       transition: color 0.3s ease;
       padding: unset;
  }

   .menu-item {
    display: flex;
    padding-inline: 0px;
}

    .dropdown-toggle {
        column-gap: 0.35rem;
        pointer-events: none;
   }
    .dropdown-content {
        position: absolute;
        left: 0;
        right: 0;
        top: 6rem;
        opacity: 0;
        height: max-content;
        pointer-events: none;
        background-color: var(--color-white-100);
        border-top: 2px solid var(--color-white-200);
        box-shadow: var(--shadow-large);
        /* transition: top 0.4s, opacity 0.3s ease; */
   }
    .dropdown-column {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        column-gap: 2rem;
        max-width: 75rem;
        margin-inline: auto;
   }
    .dropdown-group {
        align-content: baseline;
        row-gap: 1.25rem;
        padding-block: 4rem;
   }
    .dropdown-group:first-child, .dropdown-group:last-child {
        margin: unset;
   }
    .dropdown-items {
        padding-top: unset;
   }
    .dropdown-block {
        padding-top: unset;
        padding-inline: unset;
   }
    .dropdown:hover > .dropdown-content {
        cursor: initial;
        top: 4.25rem;
        opacity: 1;
        pointer-events: initial;
   }
    .dropdown:hover > .dropdown-toggle i.bx {
        rotate: 180deg;
   }
   header .userPanel.mobile .btn {    min-width: 190px;}
   /**************** menu css *******************/
   
}

@media only screen and (max-width: 1295px) {
    /**************** menu css *******************/
    .postion-line{margin-left: 0px;}
    .header nav ul {
        display: block;
        list-style-type: none;
        padding: 0;
        margin: 0;
        padding-top: 30px;
    }

    .navbar-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 95%;
        height: 100%;
        padding-left: 20px;
    }
    .navbar-block {
        position: absolute;
        left: 0;
        width: 100%;
        height: calc(100vh - 4rem);
        opacity: 0;
        overflow: auto;
        pointer-events: none;
        background-color: var(--color-white-100);
        /* transition: opacity 0.4s ease; */
    }
    .navbar-block.is-active {
        opacity: 1;
        pointer-events: initial;
    }
    
    .dropdown-toggle {
        cursor: pointer;
        outline: none;
        user-select: none;
    }
    .dropdown-toggle i.bx {
        font-size: 1.5rem;
        line-height: inherit;
        transition: rotate 0.4s ease;
    }
    .dropdown-content {
        height: 0;
        overflow: hidden;
        background-color: var(--color-white-100);
        transition: height 0.5s ease;
    }
    .dropdown-column, .dropdown-group, .dropdown-items {
        display: grid;
    }
    .dropdown-group {
        padding-bottom: 1rem;
        padding-inline: 1.5rem;
    }
    .dropdown-title {
        display: flex;
        column-gap: 1rem;
        align-items: center;
        padding-inline: 1rem;
    }
    .dropdown-icon i.bx {
        font-size: 1.5rem;
        line-height: 1.5;
        color: var(--color-blue-300);
    }
    .dropdown-items {
        row-gap: 0.5rem;
        padding-inline: 3.5rem;
        padding-top: 0.35rem;
    }
    .dropdown-link {
        font-size: var(--text-base);
        font-weight: 400;
        line-height: inherit;
        color: var(--color-black-500);
        transition: color 0.3s ease;
    }
    .dropdown-show > .dropdown-toggle i.bx {
        rotate: 180deg;
    }
    .dropdown-block {
        display: flex;
        align-items: flex-start;
        column-gap: 1rem;
        padding-top: 1rem;
        padding-inline: 1rem;
    } 
    .menu .menu-item a, .menu .menu-item span{
        color: var(--black-white-black, #000);
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 27px */
letter-spacing: 0.36px;
    }
    /**************** menu css *******************/
}

/**************************  menu css start *************************************/ 
.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: auto;
    border-bottom: 1px solid #f2f2f2; 
}
.navbar {
    width: 100%;
    height: 4.25rem;
    margin-inline: auto;
}
.postion-line{
    position: relative; top: 1px;
}
.postion-line span {position: absolute; top:8px; right: -2px; border-right:0.1rem solid #E5E9EB; height:1.5rem;}
.life-Insurance-drop-down .sub-menu-title .font-semibold{
    color: #0C4A0D;
}
 .burger .active:nth-child(1) {
    top: 0.5rem;
    rotate: 135deg;
}
.burger .active:nth-child(2) {
    opacity: 0;
}
.burger .active:nth-child(3) {
    top: 0.5rem;
    rotate: -135deg;
}
.menu-item button:hover, .menu-item a:hover{
    color: #008DC0!important;
}

/**************************  menu css end *************************************/ 

