.footer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 48px 0px 0 0px;
    border-top: 1px solid var(--Light-Gray-Light-Gray-1, #D5DADD);
background: var(--black-white-white, #FFF);
}
 
.media-icons{
    display: flex;
}
.social-media-columns-box .social-icons {
    margin: 35px 0 20px 0;
}
.social-media-columns-box img{
    height: 30px;
    background-color: #EEF0F2;
    border-radius: 40px;
    width: 30px;
    padding: 4px;
}
.applelogoimg {
    margin-top: 35px;
    width: 130px;
    margin-bottom: 0;
}
.applelogoimg:last-child {
    margin-top: 10px!important;
   
}
.footer-list-box h4{
    color: var(--Dark-Gray-Dark-Gray-1, #1A2024);

font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 21px */
margin-bottom: 20px;
}
.footer-list-box ul li {
    color: var(--Mid-Gray-Mid-Gray-1, #5B6871);
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 10px;
}
.footer-list-box {
    padding-left: 50px;
}
.sub-footer-menu{
    margin-top: 40px;
}
.footer-bottom-section {
    width: 100%;
    display: inline-block !important;
    text-align: center;
    border-top: 1px solid var(--Light-Gray-Light-Gray-1, #D5DADD);
    padding: 24px 0 0px 0;
}
.robotofont{font-family: 'Roboto'!important;}
.footer-bottom-section h5, .footer-bottom-section h6{
    color: var(--Mid-Gray-Mid-Gray-3, #84919A);
text-align: center;
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 16.5px */
}
.Savings-Protection-Plans-Protection-Plans .footer-list-box:first-child{
    margin-bottom: 50px;
}
.columns .Claims, .columns .Protection-Plans, .columns .More{
    margin-top: 50px;
}
.footer-flex{
    display: flex;
}

  .footer-list-box ul li a:hover {color:#00719A;}
  [dir='rtl'] .footer-list-box ul li a {font-weight: 300;}
  [dir='rtl'] .footer-list-box {
    padding-right: 90px;
    padding-left: 0;
}

[dir='rtl'] .footer-bottom-section h6, [dir='rtl'] .footer-bottom-section h5 a{
    
    font-weight: 300;
}
.logo-footer-box{
    margin-bottom: 30px;
}
.footer .apple-logo-box{
    margin-bottom: 20px;
}

@media (max-width: 767px){
    .socialLinks a {
        width: 35px !important;
        height: 35px !important;
    }
    .socialLinks a img {
        object-fit: contain;
        width: 25px;
        height: 25px;
    }
    .footer-list-box {
        padding-left: 5px;
    }
    [dir='rtl'] .footer-list-box {
        padding-right: 0px;
        padding-left: 0;
    }
    .logo-footer-box{
        margin-bottom: 30px;
    }
    .footer .apple-logo-box{
        margin-bottom: 20px;
    }
    .Support ul li:nth-child(5) a{
        text-wrap: wrap;
    }
    .footer-flex {
        display: block;
    }
    .footer-list-box ul li {
        width: 50%;
        float: left;
    }
    .sub-footer-menu {
        width: 100%;
        float: left;
    }
    .footer-list-box h4{
        margin-top: 30px;
    }
    .footer-section {
        padding: 48px 10px 0 0px;
    }
    .social-media-columns-box{
        width: 100%;
    }
    .apple-logo-box{
        display: inline;
        width: 80%;
    }
    .applelogoimg {
        margin-top: 35px;
        width: 90%;
        margin-bottom: 0;
        float: left;
    }
    .applelogoimg:last-child {
        margin-top: 34px !important;
        margin-left: 12px;
    }
    .footer-bottom-section{
        padding: 24px 20px 20px 20px;
    }
    .socialLinks{
        display: inline-flex!important;
        margin-top: 16px;
    }
    .applelogoimg:last-child {
        margin-top: 0px !important;
        margin-left: 0;
        margin-right: 16px;
    }
    [dir='rtl'] .apple-logo-box{
        /* direction: ltr; */
    }
    [dir='rtl'] .applelogoimg {
        width: 90%;
        margin-right: 0px;
        margin-left: 15px;
    }
    .footer-section{
        padding: 48px 20px 0 20px;
    }
 
}
 
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    /* Your styles for tablet landscape screens go here */
    .logo-footer-box{
        margin-bottom: 30px;
    }
    .apple-logo-box{
        margin-bottom: 20px;
    }
    .applelogoimg{
        margin-right: 10px;
    }
    .footer-section{
        padding: 48px 24px 0 24px;
    }
    .apple-logo-box {
        margin-bottom: 20px;
        flex-flow: column;
    }
  }
 
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    /* Your styles for tablet landscape screens go here */
    .footer-list-box {
        padding-left: 20px;
    }
    .logo-footer-box{
        margin-bottom: 30px;
    }
    .apple-logo-box {
        margin-bottom: 20px;
        flex-flow: column;
    }
    .applelogoimg{
        margin-right: 10px;
    }
    .footer-section{
        padding: 48px 24px 0 24px;
    }
  }
 