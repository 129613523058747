@font-face {
    font-family: 'GE SS';
    src: url('GESSTwoBold-Bold.eot');
    src: url('GESSTwoBold-Bold.eot?#iefix') format('embedded-opentype'),
        url('GESSTwoBold-Bold.woff') format('woff'),
        url('GESSTwoBold-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GE SS';
    src: url('GESSTextLight-Light.eot');
    src: url('GESSTextLight-Light.eot?#iefix') format('embedded-opentype'),
        url('GESSTextLight-Light.woff') format('woff'),
        url('GESSTextLight-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GE SS';
    src: url('GESSTextUltraLight-UltraLight.eot');
    src: url('GESSTextUltraLight-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('GESSTextUltraLight-UltraLight.woff') format('woff'),
        url('GESSTextUltraLight-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GE SS';
    src: url('GESSTwoMedium-Medium.eot');
    src: url('GESSTwoMedium-Medium.eot?#iefix') format('embedded-opentype'),
        url('GESSTwoMedium-Medium.woff') format('woff'),
        url('GESSTwoMedium-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GE SS';
    src: url('GESSTextItalic-LightItalic.eot');
    src: url('GESSTextItalic-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('GESSTextItalic-LightItalic.woff') format('woff'),
        url('GESSTextItalic-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

