@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./assets/Fonts/inter-t/stylesheet.css');
@import url('./assets/Fonts/roboto-t/stylesheet.css');
@import url('./assets/Fonts/GE-SS/stylesheet.css');
@import url(tempStyle.css);

:root {
  --normal-font-size:1rem;
}

.mainContainer {min-height:29rem; padding-top:5rem;}
header nav .menu-link {justify-content: center; align-items: center; margin:0 0.8rem} 
.menu-item svg {width: 0.8rem; margin-top: 0.1rem;}
body[dir="rtl"] .menu-item svg {margin-right:10px ;}

/* body header .userPanel .menu-link, body header .menu-link button, body header .menu-link a {margin:0 8px} */
body , .numberField select
{font-family: 'Roboto'}
/* Arabic code  */
[dir='rtl'] .dateField{font-family: 'Roboto'}
[dir='rtl'] body{
  font-family: 'GE SS';
}
 .call-w-full span:first-child {
  font-family: 'Roboto'!important;
}
[dir='ltr'] .call-w-full span{font-family: 'GE SS'}
/* Form inputs design guide */
/* form input {border-color:#B0BABF!important; outline-width: 0;} */
/* form input:hover {border-color:#84919A!important ;} */
/* form input:active {border-color:#1A2024!important ;} */
/* form input:focus, form input.border-gray-300 {border-color:#B0BABF!important; border-width: 1px!important;} */

/* Buttons Design Guide */
.primary-button:hover {background-color:#105F10;}
.primary-button:active {background-color:#0C4A0D;}

.secondary-button:hover, .sign-in:hover {background-color:#F6F8F9; border-color:#E5E9EB;}
.secondary-button:active, .sign-in:active {background-color:#E5E9EB;}

.modal .primary-button:hover, .get-quote:hover {background-color: #00A9E7!important;} 
.modal .primary-button:active, .get-quote:active {background-color: #55C6EF!important;} 
.model .primary-button:disabled {background-color: #55C6EF!important; opacity: 0.5;}

/* Header Section */
.logoPanel .brand {
  width: 10.5rem;
  display: flex;
  padding-bottom: 9px;
  margin-left: 7px;
}
[dir='rtl'] .logoPanel .brand{    margin-left: 0px; margin-right: 7px;}
/* Navigation menu */
.header .inner {max-width: 1180px; margin: 0 auto; padding: 0 1rem;}
.level1 {position:absolute; background: #fff; top: 5rem; left: 0; width: 100%; z-index: 99; justify-content: center; 
  border-radius: 0 0 6px 6px;}
.level1.claim-dropdown {left: auto; width: auto; height: auto;}
.mobile {display: none;}
#GeneralInsurance-dropdown-section-1 ul.claimLevel1 {
  -moz-column-count: 2;
-webkit-column-count: 2;
column-count: 2;
}
.plus img {
  width: auto;
  max-width: unset;
  height: 100%;
}
[dir='rtl'] .support li:last-child a{font-family: 'Roboto'!important}
/* Breadcrumb UI */
.breadcrumb {display: flex; align-items: baseline;}
.breadcrumb .text-blue-600 {color:#6E7C87}
.breadcrumb .text-gray-600 {color:#252C32}
.breadcrumb a, .breadcrumb span {font-weight: normal; font-size: 0.9rem;}

/* Social Links */
.socialLinks a {
  width: 32px;
  background-color: #EEF0F2;
  padding: 4px;
  border-radius: 50%;
  height: 32px;
}
.socialLinks a img {
  object-fit: contain;
  width: auto;
  height: auto;
}

/* common buttons */
.btn {padding:0.5rem; color: #000; border-radius: 10px; border:1px solid #DDE2E4; font-size: 0.875rem;}
.button-div {width: 100%; display: flex;}
.button-div button:first-child {margin-left: 0;}
.widePageWrap .button-div button:only-child {max-width: 50%;}
[dir='rtl'] .button-div button:first-child {
  margin-left: 0;
  margin-right: 0;
}
.button-div button {padding:1rem; min-width: 160px; font-size:1.125rem;
border-radius: 6px;
display: flex;
flex-direction: column;
align-items: center;
gap: 10px;
flex: 1 0;
text-align: center;

font-style: normal;
font-weight: 600;
line-height: 20px;
letter-spacing: -0.18px; margin: 0 10px; justify-content: center;
}
.single-button{
min-width: 250px;
}
.full-width-button{
display: flow;
}
.full-width-button .secondary-button {
width: 100%;
margin-bottom: 16px;
text-wrap: wrap;
}
[dir='rtl'] .robotofont {
  font-family: 'Roboto' !important;
  direction: ltr;
}
[dir='rtl'] .slick-slide{
  direction: rtl;
}
.full-width-button .primary-button  {
width: 100%;
margin-left: 0;
text-wrap: wrap;
}
.container {
max-width: 1128px;
margin: 0 auto;
}
.arabic{
font-family: "GE SS";
}
.primary-button {
    background: var(--Life-Colors-Primary-500, #168617);
    text-wrap: wrap;
    color: #fff;
}

.seconday-button, .secondary-button {
background: var(--black-white-white, #FFF);
text-wrap: wrap;
}
.breadcrumbs-section .breadcrumb{
display: flex;
}
.breadcrumbs-section .breadcrumb li{
color: var(--Life-Colors-Primary-800, #0C4A0D);
text-align: center;

/* Text/Regular/Semi Bold */
font-family: 'Roboto';
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 24px */
}
[dir='rtl'] .faq-question .font-semibold{text-align: right;}
.calculatorBox form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Standard appearance none */
  background-image: url('../src/assets/images/selectsmalldown.png');
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center; /* Adjust position as needed */
  padding-right:35px; /* Ensure space for arrow */
}
[dir='rtl'] .currencyField .md\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem* var(--tw-space-x-reverse));
  margin-left: calc(0rem* calc(1 - var(--tw-space-x-reverse)));
}
[dir='rtl']  .calculatorBox form select {
  padding-left: 35px;
  background-position: 3%;
  padding-right:10px;
  
}
[dir='rtl'] .calculatorBox form label{font-family: 'GE SS'!important;}
.calculatorBox form label{font-family: 'Roboto'!important;}
.calculatorBox form label, .calculatorBox form .currency-lable{color: var(--Dark-Gray-Dark-Gray-5, #48535B); font-size: 16px; font-style: normal; font-weight: 400; line-height: 26px; /* 162.5% */ letter-spacing: -0.16px;}
.calculatorBox form input ::placeholder{color: var(--black-white-black, #000); font-family: Roboto; font-size: 18px; font-style: normal; font-weight: 400; line-height: 26px; /* 144.444% */ letter-spacing: -0.18px;}
.calculatorBox form .border-gray-300{border-color: rgb(176 186 191 / var(--tw-border-opacity)); border-radius: 10px;}
.calculatorBox form input, .calculatorBox form select{margin-bottom: 10px; height: 50px; border-radius: 10px;}
.mgr-btm-0{margin-bottom: 0!important;}
.country-dropdown li .ml-1, .country-selector .ml-2{color: var(--black-white-black, #000); font-family: Roboto; font-size: 18px; font-style: normal; font-weight: 400; line-height: 26px; /* 144.444% */ letter-spacing: -0.18px;}
[dir='rtl'] .calculatorBox .md\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-left: calc(1rem* var(--tw-space-x-reverse))!important;
  margin-right: calc(1rem* calc(1 - var(--tw-space-x-reverse)))!important;
}
[dir='rtl'] .makeClaim p{text-align: right;}
[dir='rtl'] .MakeClaimBox li {
  list-style-type: decimal;
  margin-right: 1rem;
  text-align: right;
}
/* Hero banner Comp */
[dir='ltr']  .menu-language-btn{
  font-family: "GE SS";
}
.menu-language-btn{
  color: var(--Dark-Gray-Dark-Gray-2, #252C32);
  text-align: right;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 192.857% */
}

.widePageWrap {background:#F7EEDF; padding:5rem 0!important;}
.learning-center-box:nth-child(odd){background-color: #ffffff;}
.widePageWrap .inner {max-width: 1128px; margin: 0 auto;}
.widePageWrap .imageBox img {border-radius: 0.5rem; max-height: 500px; width: 100%; object-fit: cover;}
.widePageWrap .contentBox .left {display: flex; justify-content: space-between; flex-direction: column;}
.widePageWrap h3 .subTitle {position: relative; padding-left:1.5rem;}
.widePageWrap h3 .subTitle::after {content:":"; position: absolute; left: 0; font-size:2.5rem; top:0}
.life-Insurance-drop-down .subMenuHead h3{
  color: var(--Life-Colors-Primary-800, #0C4A0D);
  
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.28px;
  padding-left: 8px;
}
.GeneralInsurance-dropdown .subMenuHead h3 {
  padding-left: 8px;
  font-weight: 700;
  font-size: 0.875rem;
}
.multi-tel{border-radius: 8px;}
/* Common classes */
.articleHead {color: var(--black-white-black, #000);
font-family: 'Roboto';
font-size: 2.5rem;
font-style: normal;
font-weight: 600;
line-height: 120%;}

.articleP, .articleListP { color: var(--black-white-black, #000);

font-size: 1.5rem;
font-style: normal;
font-weight: 400;
line-height: 30px;}

  .articleListHead  {color: var(--black-white-black, #000);

font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 22px;
margin-bottom: 15px;}

.contentBox {padding: 3rem 0 0;}
.right ul {margin-bottom:1.5rem;}
.right ul li {margin: 0 0 1rem 0; background: url(../src/assets/images/trueList.png) 0 4px no-repeat; padding: 0 0 0 40px; font-weight: 500;}

.faqWrapper {
  max-width: 60.5%;
  margin: 0 auto 0;
  }
.faqWrapper .articleHead {padding-top:4rem;}
.faqWrapper SPAN.text-xl {font-size: 2rem; font-weight:300 ;}

.all-plans {max-width: 50vw; margin:  0 auto; padding: 3rem 0;}
.all-plans__grid section {align-items: center; justify-content: center; padding:2rem; text-align: center; min-height:250px; border: 1px solid #D5DADD;}
.all-plans__grid section h2 {font-size:1.8rem; margin-bottom:1.5rem; line-height: 35px;}
.all-plans__grid section img {
  margin: 0 0 2rem 0;
  display: inline-flex;
}

/* PLP Page  */

[dir='rtl'] .right ul li {background-position: 100%; padding-right:40px; font-weight: 500;}
[dir='rtl'] .space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-left: calc(1rem* var(--tw-space-x-reverse));
  margin-right: calc(0.7rem* calc(1 - var(--tw-space-x-reverse)));
  }
  
/* PDP page  bug fixed for image rotate*/ 
[dir='rtl'] .hero-banner .object-cover { -webkit-transform: scaleX(-1); 
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
}
.pdpPage .your-partner-section-outer-box * {color: #fff;}
.pdpPage .start-invensting-section .button-div button {background:#fff!important; color: #000!important;}
.stillBox {text-align: center; margin:3rem 0 5rem;}
.stillBox h3 {margin-bottom:1.5rem; font-size: 1.5rem; font-weight: bold;}
.stillBox .button-div {max-width: 400px; margin: 0 auto;}
.blog-small-title{color: var(--Life-Colors-Primary-800, #0C4A0D); text-align: left; /* Text/Regular/Semi Bold */  font-size: 1rem; font-style: normal; font-weight:500; line-height: 150%; /* 24px */}
.blog-small-title{text-align: left!important;}
.blog-section .stay-tuned-section:first-child{ background: var(--black-white-white, #FFF);}
.blog-section .stay-tuned-section:nth-child(2){background: var(--Primary-PR050, #EDFAFF);}
.blog-section .stay-tuned-section:nth-child(3){background: var(--black-white-white, #FFF);}
.blog-section .stay-tuned-section:nth-child(4){background: var(--Life-Colors-Skin-200, #FBF7F0);}
.insurence-plan-box .imgBox {display: flex; justify-content: center;}
.insurence-plan-box .imgBox img{ min-height: 300px; max-height: 300px; border-radius: 10px; min-width:280px; width: 100%; }
.insurence-plan-box:hover {cursor: pointer;}

/* 404 Page */

.notFoundBox {margin: 2rem auto; max-width: 35%; }
.notFoundBox .sectionHead {font-size: 2.5rem; font-weight: bold; line-height:40px;}
.notFoundBox li a {font-weight: bold; margin-bottom: 3rem;}
[dir='rtl'] .rounded-dot::before { transform: translateX(-50%); border-radius: 100%; position: absolute; background: #48535B; bottom: 6px; height: 4px; content: ''; width: 4px; right: 4%; } 
[dir='rtl'] .rounded-dot { position: relative; padding-right: 20px; padding-left: 0;}
[dir='rtl'] .blog-small-title{text-align: right!important; font-family: "GE SS";}
[dir='rtl']  .left-2\/2 {
left: 9.666667%;
}
[dir='rtl'] .faq-section h2{font-family: "GE SS";}
.articl-rounded-image img{border-radius: 20px;}
body[dir="rtl"] header .userPanel button:first-child {margin-left: 1rem;} 

/*********** font family for arabic CSS start **************/

[dir='rtl'] .insurence-plan-box h4{
font-family: "GE SS";
font-size: 24px;
font-style: normal;
font-weight: 700;
  }
  [dir='rtl'] .rounded-profile-box .profile-content h5{
font-family: "GE SS";
font-size: 14px;
font-style: normal;
font-weight: 500;
  }
  [dir='rtl'] .faq-section h4{
font-family: "GE SS";
  }
  [dir='rtl'] .faq-section span{
font-weight: 700;
  }
  [dir='rtl'] .articleHead{
font-family: "GE SS";
  }
  [dir='rtl'] .profile-content h6{
font-weight: 300!important;
font-family: "GE SS";
  }
  [dir='rtl'] .learning-center-title {
text-align: right!important;
font-family: "GE SS";
}
[dir='rtl'] .insurence-plan-box p{
font-family: "GE SS";
  }
  [dir='rtl'] .breadcrumbs-section ol li:first-child a, [dir='rtl'] .breadcrumbs-section ol li:nth-child(2) span{
font-family: "GE SS";
  }
[dir='rtl']  .learning-center-info{
text-align: right!important;
font-family: "GE SS";
}

 /****** ***** font family for arabic CSS start **************/

[dir='rtl'] .insurence-plan-box p:first-child{
font-family: "GE SS";
font-size: 14px!important;
font-weight: 500!important;
  }
.breadcrumbs-section ol li:first-child a{color: var(--Mid-Gray-Mid-Gray-2, #6E7C87);/* Headings/UI/UI Text 14 Reg */
font-family: 'Roboto';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */}
.breadcrumbs-section ol li:nth-child(2) span {color: var(--Dark-Gray-Dark-Gray-2, #252C32);/* Headings/UI/UI Text 14 Reg */
font-family: 'Roboto';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */}
.view-all-screen{background-color: #0000;}
.faq-section{
  padding-top: 100px;
}
.faq-section h2{
  color: var(--black-white-black, #000);
  text-align: center;
  
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.faq-section h4 {
color: var(--black-white-black, #000);
text-align: center;

font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 160%;   
}
[dir='rtl'] .secondary-button, [dir='rtl'] .primary-button{
  font-family: "GE SS";
}
[dir='rtl'] .discover-columns-box h2{font-family: "GE SS";}
[dir='rtl'] .claim-menu-title, [dir='rtl'] .navbar-block .menu li a, [dir='rtl'] .sign-in-btn, [dir='rtl'] .get-quote-btn{
  font-family: "GE SS";
}
.faq-section .faq-box{padding:1rem 1.2rem 0.8rem;}
.faq-description-title{
  margin-bottom: 40px;
  text-wrap: unset;
}
.faq-answer {font-size: 1rem; line-height:22px; font-weight: 400;}

/* Modal popup  */
.pop-upBox {position: relative;}
.pop-upBox .btn-close {position: absolute; top: 10px; right: 10px;}
[dir='rtl'] .pop-upBox .btn-close {right: auto; left: 10px;}
.modal .formField {position: relative; min-height:80px;}
.modal .formField .text-red-500 {position: absolute; bottom: -16px; left: 5;}
.bg-gray-600 {
  --tw-bg-opacity: 1;
  background: rgba(0, 0, 0, 0.50);
}
.modal .primary-button {
  background: #55C6EF !important;
  color: #1A2024 !important;
  padding: 16px;
  margin-top: 30px;
  font-family: 'Roboto' !important;
}
[dir='rtl'] .modal label{font-family: 'GE SS'!important;}
[dir='rtl'] .modal .primary-button{font-family: 'GE SS'!important;}
[dir='rtl'] .speck-to-advisor h2{font-family: 'GE SS'!important;}
.modal .shadow-lg {border-radius:20px ;}
.speck-to-advisor{
  width: 541px!important;
  padding: 2.5rem;
}
.speck-to-advisor h1{
  color: var(--black-white-black, #000);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.speck-to-advisor h2{
  color: var(--black-white-black, #000);
  font-family: 'Roboto'!important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 8px;
}
.speck-to-advisor label{
  color: var(--Dark-Gray-Dark-Gray-5, #48535B);
  font-family: Roboto!important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.16px;
  margin-top: 15px;
}
.speck-to-advisor input {
  /* border-radius: 10px;
  border: 1px solid var(--Mid-Gray-Mid-Gray-5, #B0BABF); */
  background: var(--black-white-white, #FFF);
  color: var(--black-white-black, #000);
  
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.18px;
}
.speck-to-advisor .multi-tel{
  border-radius: 10px;
   /* border: 1px solid var(--Mid-Gray-Mid-Gray-5, #B0BABF);  */
  background: var(--black-white-white, #FFF);
  margin-bottom: 5px;
}
.speck-to-advisor .multi-tel input {
  border: 0px;
  border-radius: 10px;
}

.speck-to-advisor .border-gray-200 {
  --tw-border-opacity: 1;
  border-color: 1px solid var(--Mid-Gray-Mid-Gray-5, #B0BABF);
  border: 1px solid var(--Mid-Gray-Mid-Gray-5, #B0BABF);
}
.speck-to-advisor .multi-tel .country-selector{
  z-index: 0;
  margin-left: 10px;
}
.confirmation-title {
  color: var(--black-white-black, #000);
 
  font-size: 32px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 120%;
  margin-top: 24px;
  margin-bottom: 24px;
}

/* Learning center page */

  .learinig-center-blogs .insurence-plan-box {margin-bottom:2.5rem;}
  .learinig-center-blogs  .stay-tuned-section h2 {text-align: left;}


/*  Calculator  */

  .calculatorBox {overflow: hidden;}
  .calculatorBox .button-div {padding-top:1rem;}
  .calculatorBox .resultBox .button-div {max-width: 100%;}
  .yearsField span.absolute {
    background: #F6F8F9;
    color: #6E7C87;
    top: 2px;
    right: 2px;
    height: calc(100% - 14px);
    border-radius: 0 10px 10px 0;
    padding: 1rem;
    max-width: 50%;
}
body[dir="rtl"] .yearsField span.absolute {left: 0; right:auto; border-radius: 10px 0 0 10px; margin:0 0 0 1px ;}

/*  Learning center Article Page */

.articleDetails .articleTop {align-items: baseline;}
.articleDetails .leftPanel li {margin-bottom:0.5rem; padding:0.5rem 1rem ; color: #000;}
.articleDetails .blogBanner {margin-bottom: 2rem; border-radius: 15px; object-fit: cover; max-height: 500px;}
.leftPanel li.font-bold {
  border-radius: 10px;
  background: var(--Light-Gray-Light-Gray-5, #F6F8F9);
}
.leftPanelHead {font-size: 2rem; font-weight: bold; margin-bottom:1rem;}
.articleBox h2 {font-size:2.5rem; font-weight: bold; margin-bottom: 1.2rem;} 
.articleBox p {margin-bottom: 1.5rem; font-size: 1rem;}
.articleBox p strong {font-size: 1rem;}  
.articleDetails .noteBox {border:0; background:#EDFAFF; padding: 1.5rem 1.5rem 1.5rem 0; position: relative;}
.articleDetails .noteBox p {border-left:3px solid #00A9E7; padding-left: 1.5rem;}
.articleDetails .stay-tuned-section {background:#FBF7F0!important}
.articleDetails .verticleTileWrap {background:#EDFAFF!important}

/* Article Listing Page */

.articleListing .insurence-plan-box {margin-bottom:2rem ;}
.MakeClaimBox p {margin-bottom: 1.5rem;}
.calculatorBox.makeClaim .leftPanel {max-width: 90%;}
.mobileField{margin-bottom: 25px!important;}
[dir="rtl"] .country-selector{
  margin-right: 7px;
}
body[dir="rtl"] .calculatorBox.makeClaim .leftPanel {max-width:85%;}
.MakeClaimBox ol {margin-bottom: 1.5rem; list-style-type: decimal;}
.MakeClaimBox li {list-style-type: decimal; margin-left: 2rem;}  
.makeClaim .customButton {cursor: unset;background: #fff; border:1px solid #94C794; border-radius: 10px; min-width:45%; align-items: start; padding: 2rem 2rem 1rem;}
.makeClaim .customButton span:first-child {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: right;
  line-height: 1.2;
}

/* Calculator result page */

  .resultBox {text-align: center; background:#F6F8F9; border-radius: 20px; padding: 2rem 5rem;}
  .disclaimer {font-style: italic; font-size: 0.75rem; text-align: left;}
  .resultBox .my-6.text-lg {border-bottom: 1px solid #ccc; padding: 1rem 0;}
  /* .mobileField .relative.rounded  {border: 1px solid #ccc; border-radius: 0.5rem;} */
  .mobileField .relative.rounded input, .mobileField .relative.rounded .country-selector {border-radius: 1rem;}
 
  .contentBox .button-div .secondary-button{ margin-bottom: 15px; }
  .faq-question .font-semibold{ color: var(--black-white-black, #000); /* Text/Medium/Bold */; 
    font-size: 18px; font-style: normal; font-weight: 700; line-height: 160%; /* 27px */ text-align: left; }
  .faq-answer { font-size: 1rem; line-height: 22px; font-weight: 300; }
  /* button[aria-expanded="true"] {color: var(--Primary-PR600, #008DC0)!important; text-align: right;  font-size: 18px; font-style: normal; font-weight: 400!important; line-height: 27px; 150% } */
  
  .menu-item .justify-between { justify-content: left; }
.postion-line a{ padding-left: 5px!important; }
.mainContainer .hero-banner{ padding-top: 8rem!important; }
/* body[dir="ltr"] .mainContainer .hero-banner img {-webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);} */
  /* body[dir="rtl"] .mainContainer .hero-banner img {-webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1);} */
.widePageWrap .button-div { flex-direction: row; display: block; }

.slick-dots li:first-child {
    border-radius: 11px 0 0 11px;
}
.claim-dropdown .subMenuHead h3{    margin-left: 9px;}
[dir="rtl"] .claim-dropdown .subMenuHead h3{    margin-right: 9px;}
[dir="rtl"] .country-selector .ml-2{font-family: 'Roboto'!important;}
[dir="rtl"] .country-dropdown .ml-1{font-family: 'Roboto'!important;}
[dir="rtl"]  .life-Insurance-drop-down .subMenuHead h3{    padding-right: 8px;}
.articleListing .stay-tuned-section{padding: 0rem 1.5rem;}
.custom-mb-10{margin-bottom: 64px;}
.articleListing .stay-tuned-section .blog-small-title{color: var(--black-white-black, #000);
  text-align: center;
  
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;}
  .articleListing .mb-5{ margin-bottom: 0!important; padding-bottom: 0!important;}
  .multi-tel input {
    font-family: 'Roboto';
}
.call-w-full{width: 100%!important;}

/* Global Media Query */
/* SmartPhones */
@media only screen and (max-width: 400px){
  .hero-banner-box .button-div button{        font-size: 1rem!important;}
}

 
@media only screen and (max-width: 767px) {
  body[dir='rtl'] header .userPanel .btn:first-child{    min-width: 122px!important;}
  .btn-close img{width: 45px;}
  .articleHead {
    font-size: 1.875rem!important;
    text-wrap: unset;
}
.makeclaimfaq{font-size: 2rem;}
.right ul li {
  margin: 0 0 0.5rem 0;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEVSURBVHgBpZQLEcIwEEQXFFRCJUQCEuqgkYADggKQgANAQXEADoqDSoAc3MH2N01hZ5aW3PXNpnMNMKwseh19jK6jH+qrrnnMUBHdEGTMdQp4Rw9cNGVOdacQTr0Zg220oVHQlNb47mTXLXqCOaTLEbTggm0hJRnDLKmFyThdjXSVaL+/ygItKeo2jfWCHfR+odezXlfyc1V6yruzZOJA6zlol9bAcjNgpg+nC7TxKWfAWpy73uRaCFQsE2EO308TJ/RHhqFTMJHXHmF95qjqNIVEmMjm2MsfGcYGw4MdMA2zQK05LvD/p+e7xT1+PxzCWFNA/7zjxLmCKurbY0Ie7fNuzJKuwAx5vMfgRpC7rknKbOihJxCwil40Lp+FAAAAAElFTkSuQmCC) 0 4px no-repeat;
  padding: 0 0 0 40px;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 16px;
}
.articleP{
  color: var(--black-white-black, #000);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
  [dir="rtl"] #life-Insurance-drop-down-section-1 .subMenuHead{    padding: 0.5rem 0rem 1rem 3rem;}
  [dir="rtl"] #life-Insurance-drop-down-section-2 .subMenuHead{    padding: 0.5rem 0rem 1rem 3rem;}
  [dir="rtl"] #life-Insurance-drop-down-section-3 .subMenuHead{    padding: 0.5rem 0rem 1rem 3rem;}
  [dir='rtl'] .calculatorBox .md\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-left: calc(1rem* var(--tw-space-x-reverse)) !important;
    margin-right: calc(0rem* calc(1 - var(--tw-space-x-reverse))) !important;
}
  [dir="rtl"]  .postion-line a{
    margin-right: 20px!important;
  }
  body[dir="rtl"] .calculatorBox.makeClaim .leftPanel {
    max-width: 100%;
}
  .logoPanel .brand {position: relative; top:-3px;}
  .widePageWrap .button-div button:only-child {max-width: none;}
  body .header .inner .navbar-block.is-active {max-width: none;}
  body .header .inner .menu .menu-item a {padding: 0.5rem 1rem!important;}
  [dir="rtl"] body .header .inner .menu .menu-item a {padding: 0.5rem 1rem!important;}
  .calculatorBox.makeClaim .leftPanel {
    max-width: 100%;
    padding: 20px 12px 40px 12px;
}
[dir="rtl"] .claimLevel1 li a img {
  margin-right: 0!important;
}   
[dir="rtl"] .menu-item .mr-8{
  margin-right: 16px !important;
}

[dir='rtl'] .claimLevel1 li {
  padding: 0.3rem 0rem 0.3rem 1rem;
}
.learningCenterPage .button-div {
  flex-flow: row;
  gap: 20px;
  flex-direction: column;
}
.makeClaim .articleHead{font-size: 30px!important;}
.MakeClaimBox li {
  list-style-type: decimal;
  margin-left: 1rem;
}
.makeClaim .button-div {
  padding-top: 1rem;
  gap: 24px;
}
[dir="rtl"] .vertical-gray-line{text-align: right!important;}
.articleDetails .stay-tuned-section {
  padding: 64px 20px;
}
.articleDetails .Get-Started-with-solidatary-section .button-div {
  flex-flow: column;
  gap: 20px;
  flex-direction: column;
}
.calculatorBox .customWrapper {padding: 0!important;} 
.leftPanel{padding: 20px 20px 64px 20px!important;}
.leftPanel .button-div{flex-flow: column!important;
  gap: 20px;}
  .makeClaim .button-div{ max-width: 100%!important;}
  .articleContent .text-current, .articleContent .text-black{display: flex;
    padding: 8px 16px;border-radius: 6px 6px 0px 0px;
    background: var(--Light-Gray-Light-Gray-4, #EEF0F2);color: var(--black-white-black, #000);
    font-family: 'Roboto';
    font-size: 20px;
    font-style: normal;
    margin-bottom: 0;
    font-weight: 700;
    line-height: 150%; /* 30px */}
    .article-select{
      padding: 0 !important;
      --tw-border-opacity: 8;
      border-radius: 6px 6px 0px 0px;
      border: 1px solid var(--Light-Gray-Light-Gray-4, #EEF0F2);
      background: var(--black-white-white, #FFF);
    }
    .article-select ul{
      padding: 16px 16px 16px 16px;
  }
  .article-select button[aria-expanded="true"] {
    color: var(--black-white-black, #000) !important;
}
.article-select button[aria-expanded="true"] svg path {
  fill: #000;
}
[dir="rtl"] #life-Insurance-drop-down-section-1 .subMenuHead{ padding: 0.5rem 0rem 1rem 3rem;}
[dir="rtl"] #claim-dropdown .subMenuHead{ padding: 0.5rem 0rem 1rem 3rem;}
[dir="rtl"] .claim-dropdown .subMenuHead {
  padding: 0.5rem 0rem 1rem 3rem;
}
.calculatorBox .button-div {max-width: 100%!important;}
}

@media only screen and (max-width: 1295px) {
  
.header .inner .navbar-block.is-active {
  top: 70px;
  background: #fff;
  max-width: 50%;
  right: 0;
  left: auto;
  box-shadow: none;
  background: #F6F8F9;
}
.overlay-on-mobile{
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 99;
  background: #0c0c0cbd;
  height: 100vh;
  display: flex;
}
body[dir="rtl"] .header .inner .navbar-block.is-active {left: 0; right: auto; text-align: left;}
[dir='rtl'] .menu-item .justify-between {justify-content: left;}
body .navbar {padding-bottom: 5rem;}
.menu.order-2.items-center {padding-bottom: 10rem;         width: 98%;}
.header .inner .menu .menu-item a {padding: 0;}
.header nav li.menu-item {padding:0.3rem;}
.header nav li.postion-line a {margin: 0 4px;}
.menu-item .justify-between {position: relative;}
.menu-item .justify-between .transform  {position:absolute; right: 1rem; top:0.5rem;}
[dir='rtl'] .menu-item .justify-between .transform {
  position: absolute;
  left: 1rem;
  top: 0.5rem;
  right: unset;
}
.calculatorBox .button-div {
  max-width: 35%;
}
.calculatorBox .button-div {
  flex-flow: row;
  gap: 20px;
}
body .navbar .level1 {width: 100%; max-width: none; background: none; box-shadow: none;}
body .navbar .menu-item {display: block;}
.header .inner .menu .menu-item a {text-align: left;}

header {box-shadow: unset;}
  /****** header menu css start ********/
  .header .inner {height:70px;}
  .inner {padding:0 1rem;}
  #GeneralInsurance-dropdown-section-1 ul.claimLevel1 {
    column-count: 1;
}
.navbar-block {grid-template-rows:40px 1fr 80px;}
body .header .inner .userPanel {position: unset;}
.menu .menu-item.postion-line a {padding:0.7rem 1rem; display: block;} 
.navbar-block .userPanel {padding:0 1rem; margin-top: 1.5rem;}
.navbar-block {top: 4.3rem;}
.burger {top:2rem}
.desktop {display: none!important;}
.mobile {display: flex!important; height: 35px;}
.userPanel.mobile button {width: 100%; text-align: left;}
[dir='rtl'] .userPanel.mobile button { text-align: right;        margin-right: 0 !important;}
body[dir="rtl"] .GeneralInsurance-dropdown .subMenuHead, body[dir="rtl"] #life-Insurance-drop-down-section-0 .subMenuHead {
  padding: 0.5rem 0rem 1rem 1rem;
}
[dir='rtl'] .claimLevel1 li {
  padding: 0.3rem 0rem 0.3rem 3rem;
}
   /****** header menu css start ********/
 /******* child-calculator ********/
 .rightPanel {text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;}
  .mainContainer .resultBox {padding: 1rem;}  
  /******* child-calculator ********/
  /******* Blog tiles ********/
  .rounded-profile-box .profile-icon {
    float: left;
    width: 15%;
}  
   /******* Blog tiles ********/   
.button-div button{
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 100%;
}/******* Primary button text wrap ********/ 
/******* blog page ********/ 
.articleHead {
  font-size: 40px;
  text-wrap: unset;
}
.articleListing .container{
padding: 0;
}
.articleTop{
display: block;
}
.content-box{
  display: contents;
  }
  .content-box .leftPanel{
  width: 100%;
  padding: 5px;
  }
  .content-box div{
  width: 100%;
  }
  .articleDetails .inner .container {
  padding: 5px;
  }
/******* blog page ********/ 
.learningCenterPage .blog-section .stay-tuned-section{
  padding: 64px 20px;
}/******* learning center page ********/ 

.footer-list-box ul li a {white-space: break-spaces;
  padding: 0 5px 0 0;}/******* footer section ************/


}

/* Tablets */

@media only screen and (max-width: 1295px) {
  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
}
  .mainContainer {padding-top: 4.5rem;}
  /* button[aria-expanded="true"] { color: var(--Primary-PR600, #008DC0)!important; text-align: right; font-family: Roboto; font-size: 18px; font-style: normal; font-weight: 400!important; line-height: 27px; 150% } */
  /******* menu section start ************/  
  .menu-item .justify-between { justify-content: left; }
    .postion-line a{ padding-left: 5px!important; }
    [dir='rtl'] .menu-item .justify-between { justify-content: right; }
    [dir='rtl'] .menu-item button .ml-2 { margin-right: 0.5rem; }
    .header .inner .userPanel {position: relative; right:50px} 
  .header .inner .navbar-block.is-active {top:70px; background: #fff;}
  .header .inner .menu .menu-item a {padding: 0.5rem 1rem!important; margin:0}
  [dir='rtl']  .header .inner .menu .menu-item a {padding: 0.5rem 0rem!important; margin:0;text-align: right;}
  [dir='rtl'] .vertical-gray-line{margin-right: 15px!important;}
  .header nav li {padding: 0;}
  button[aria-expanded="true"] {color:#55C6EF;}
  [dir='rtl'] .subMenuHead {
    padding: 0.5rem 0rem 1rem 3rem;
}
  .header nav li button, .header nav li a {font-weight:400!important; font-size:1.125rem;}
  .header nav li ul {padding-top: 0;        margin-bottom: 20px;}
  body[dir="rtl"] .header .inner .burger {left: 10px; right: auto;}
  .level1 {display: flex;
    flex-direction: column; position: unset!important; height: 100%!important}
/******* menu section end ************/  

/****start-invensting-section *******/
body .start-invensting-section .button-div {max-width: none;}
  /****start-invensting-section *******/ 
.faqWrapper {max-width: 90%!important;}/****faq-section *******/

/**********all-plans__grid ***********/
.all-plans, .notFoundBox {max-width: 90%;}
.all-plans__grid section {padding: 2rem;}
/**********all-plans__grid***********/

.stillBox {max-width: 90%; margin: 0 auto 2rem;}

/**********primary secondary button ***********/
.button-div {
  flex-flow: column;
  gap: 20px;
  flex-direction: row;
}
.button-div button {width: 100%; margin: 0 0 0rem}
.pdpPage .start-invensting-section .button-div button {max-width: 80%; margin: 0 auto;}
/**********primary secondary button ***********/

/************* blog page ****************/
.blog-sub-title{font-size: 14px!important; font-style: normal; font-weight: 600!important;}
.blog-sub-title-sub{font-size: 1rem!important; font-style: normal; font-weight: 400!important;}
.learinig-center-blogs .insurence-plan-box {
margin-bottom: 0rem!important;
}
/************* blog page ****************/
.speck-to-advisor {
  width: 90% !important;
  padding: 30px;
  }
  .btn-close{margin-top: -30px;}
  /* .widePageWrap .inner {padding: 0 1.5rem} */
  body .faqWrapper .articleP {max-width: none;} /********* faq section ***********/
  
  .stay-tuned-section .insurence-plan-box .imgBox img{width: 100%;}
  .verticleTileWrap .Get-Started-column-one {margin-bottom: 2rem;} 
  /*********discover-columns-box css ************/
body .discover-columns-box {margin-top: 1rem;}
body .discover-columns-box h2 {min-height: auto; margin-bottom: 5px;}
.claim-banner-img{border-radius: 0!important;}
/*********discover-columns-box css ************/

  }
  @media only screen and (max-width: 1295px) {
    /************ menu section css ****************/
    .header .inner .burger {position: fixed; right:24px; top:25px}
    .burger {
      position: relative;
      display: block;
      cursor: pointer;
      user-select: none;
      width: 1.5rem;
      height: 1rem;
      border: none;
      outline: none;
      visibility: visible;
   }
   .articleContent .text-current, .articleContent .text-black {
    display: flex;
    padding: 8px 16px;
    border-radius: 6px 6px 0px 0px;
    background: var(--Light-Gray-Light-Gray-4, #EEF0F2);
    color: var(--black-white-black, #000);
    font-family: 'Roboto';
    font-size: 20px;
    font-style: normal;
    margin-bottom: 0;
    font-weight: 700;
    line-height: 150%;
}
.article-select ul {
  padding: 16px 16px 16px 16px;
}
.article-select {
  padding: 0 !important;
  --tw-border-opacity: 8;
  border-radius: 6px 6px 0px 0px;
  border: 1px solid var(--Light-Gray-Light-Gray-4, #EEF0F2);
  background: var(--black-white-white, #FFF);
}
.article-select button[aria-expanded="true"] svg path {
  fill: #000;
}
.article-select button[aria-expanded="true"] {
  color: var(--black-white-black, #000) !important;
}
   .burger-line {
      position: absolute;
      display: block;
      right: 0;
      width: 100%;
      height: 2.5px;
      opacity: 1;
      rotate: 0deg;
      border-radius: 0.15rem;
      background-color: #000;
      transition: all 0.3s ease;
   }
   .burger-line:nth-child(1) {
      top: 0px;
   }
   .burger-line:nth-child(2) {
      top: 0.5rem;
      width: 100%;
   }
   .burger-line:nth-child(3) {
      top: 1rem;
   }
   .burger.is-active > .burger-line:nth-child(1) {
      top: 0.5rem;
      rotate: 135deg;
   }
   .burger.is-active > .burger-line:nth-child(2) {
      opacity: 0;
   }
   .burger.is-active > .burger-line:nth-child(3) {
      top: 0.5rem;
      rotate: -135deg;
   }
   /************ menu section css end ****************/
   
  }
.country-selector .svg-inline--fa{width: 12px;    color: gray;}
/* Desktop Basic */

  @media only screen and (min-width: 1295px) {
    /********* menu section **********/
    body .menuPanel ul li button {padding: 1rem;}
    body .navbar .level1 {height: auto; box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;}
   
    /********* menu section **********/
footer .apple-logo-box {flex-direction: column; margin-bottom: 25px;}/******** footer css *********/
body .start-invensting-section .button-div {max-width: 18rem;}/******** button css *********/

  /* .verticleTileWrap .button-div button {max-width:45%;} */
  .stay-tuned-section p.description {max-width: 60%; color: var(--black-white-black, #000);
    /* Text/Medium/Normal */
    
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */}
  .faqWrapper .articleP {margin: 0 auto 5rem;} 
  .articleListing .articleTop {max-width: 70%;} 
   /* Calculator page */
  .calculatorBox {position: relative;}
  .calculatorBox .leftPanel {max-width:80%;}
  .calculatorBox .leftPanel form{max-width:80%;}
.calculatorBox .rightPanel img {
  height: 70%;
  /* min-height:78vh; */
  position: absolute;
  right: -10rem;
  z-index: 99;
  top: 10%;
  max-width: 30%; border-radius: 30px; object-fit: cover;}
body[dir="rtl"] .calculatorBox .rightPanel img {left:-150px; right: auto}
.calculatorBox .button-div {max-width: 35%;}
.calculatorBox.makeClaim .button-div {max-width: 100%;gap: 20px;}
.button-div {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.makeClaim  .button-div {display: flex; flex-direction: unset}
.makeClaim  .button-div button {margin:0 0rem ; font-weight: bold; cursor: auto;}
.button-div button {width: 100%; margin:0;}  
.menuPanel .burger {display: flex;}

 /* Calculator page */
}

/* HD Desktop */
@media only screen and (min-width: 1240px) {
/* .mainContainer .upper-content-box {min-height: auto;} */
.pop-upBox {min-width: 550px;}
.menuPanel .burger {display: none;}

}
/* Learning center article copy link */
.copy-link-button {
  cursor: pointer;
}
.footer-section {
  padding: 48px 24px 0 24px;
}
.copy-link-notification {
  position: fixed;
  margin-top: 25vh;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  opacity: 0.9;
  z-index: 1000;
  animation: fadeInOut 2s ease-in-out;
}
.plans-section .faqWrapper {
  max-width: 60%;
}
@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 0.9; }
}
/******************** hero banner ********************/
.slick-slide img, .your-partner-section-outer-box img {object-fit: cover;}
.mainContainer .slick-dots {display: flex!important; justify-content: center; align-items: center; padding:3rem!important; position: unset;}
.mainContainer .slick-dots ul {
  margin: 0 1rem;
  display: flex;
  background: var(--Light-Gray-Light-Gray-3, #E5E9EB) !important;
  padding: 0 7px 0 7px;
  border-radius: 15px;
}
.mainContainer .slick-dots li button:before {top:2px}

.custom-arrow a {
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding: 10px 15px 10px 10px;
  position: relative;
  text-decoration: none; top:3px
}
.custom-arrow a.left{
transform:rotate(180deg);
}

.custom-arrow a:before, .custom-arrow a:after {
  border-right: 2px solid;
  content: '';
  display: block;
  height: 8px;
  margin-top: -6px;
  position: absolute;
  transform: rotate(135deg);
  right: 10px;
  top: 50%;
  width: 0;
}

.custom-arrow a:after {
  margin-top: -1px;
  transform: rotate(45deg);
}
.custom-arrow a:hover, .custom-arrow a:focus,
.custom-arrow a:hover:before, .custom-arrow a:hover:after,
.custom-arrow a:focus:before, .custom-arrow a:focus:after {
    color: #000;
}
/******************** hero banner ********************/
@media only screen and (min-width: 769px) and (max-width: 1269px){
  /* .header .inner .menu .menu-item a {
    padding: 0.5rem 0rem !important;
} */
  /* .postion-line{margin-left: 0px;} */
  /* [dir="rtl"] .postion-line a{margin-right: 0px!important;} */
  [dir="rtl"] #life-Insurance-drop-down-section-1 .subMenuHead{    padding: 0.5rem 0rem 1rem 3rem;}
  [dir="rtl"] #life-Insurance-drop-down-section-2 .subMenuHead{    padding: 0.5rem 0rem 1rem 3rem;}
  [dir="rtl"] #life-Insurance-drop-down-section-3 .subMenuHead{    padding: 0.5rem 0rem 1rem 3rem;}
  .widePageWrap .button-div {
    flex-direction: row;
    display: flex;
    gap: 20px;
}
[dir="rtl"] .postion-line {
  position: relative;
  top: 1px;
  /* padding-right: 0px !important; */
}
.widePageWrap .button-div .secondary-button {
  margin-bottom: 0;
}
.rightPanel img{
  height: 300px;
  width: 100%;
  object-fit: cover;
}
.calculatorBox{padding: 0!important;} 
.calculatorBox .customWrapper {padding: 0!important;} 
.leftPanel{
  padding: 20px 48px 100px 48px;
}
.makeClaim .customButton{min-width: 272px;}
.calculatorBox.makeClaim .leftPanel {
  max-width: 100%;
}
.calculatorBox.makeClaim .button-div {
  flex-flow: inherit;
  gap: 20px;
  max-width: 100%;
  width: 100%;
}

}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .Support{
    margin-top: 35px;
}
  body[dir='rtl'] #claim-dropdown .subMenuHead{    padding: 0.5rem 0rem 1rem 3rem;}
  body[dir='rtl'] header .userPanel .btn:first-child{    min-width: 122px!important;}
  body[dir='rtl'] header .userPanel .btn {
    min-width: 165px;
    margin-right: 5px !important;
}
  [dir="rtl"] #claim-dropdown .subMenuHead {
    padding: 0.5rem 0rem 1rem 3rem;
}
.slick-slide img {
  height: 500px!important;
  width: 100%;
  border-radius: 20px;
}
  [dir="rtl"] .postion-line a{
    margin-right: 20px!important;
  }
  /************ faq section  potrait mode ***************/
  .plans-section .faqWrapper {
    max-width: 90%;
}
[dir='rtl'] .claimLevel1 li {
  padding: 0.3rem 0rem 0.3rem 3rem;
}
.widePageWrap{
  padding: 3rem 2rem !important;
}
/************ faq section  potrait mode ***************/

/********* primary button css ***********/
.learning-center-box.widePageWrap .button-div {flex-direction: row;display: flex;gap: 20px;}
.contentBox .button-div .secondary-button {
  margin-bottom: 0;
}
/********* primary button css ***********/

.header .inner .navbar-block.is-active {
  top: 70px;
  background: #fff;
  max-width: 50%;
  right: 0;
  left: auto;
  box-shadow: none;
  background: #ffffff;
}
.overlay-on-mobile{
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 99;
  background: #0c0c0cbd;
  height: 100vh;
  display: flex;
}
body .navbar {padding-bottom: 5rem;}
.menu.order-2.items-center {padding-bottom: 10rem;}
.header .inner .menu .menu-item a {
  padding: 0.5rem 0.5rem !important;
}
[dir="rtl"] .header .inner .menu .menu-item a {
  text-align: right;
  padding-right: 0 !important;
}
.header nav li {border-bottom: 0px solid #E5E9EB; padding:0.5rem;}
.header nav li.postion-line a {margin: 0 4px;         margin-left: 8px !important;}
.menu-item .justify-between {position: relative;}
.menu-item .justify-between .transform  {position:absolute; right: 1rem; top:0.5rem;}
body .navbar .level1 {width: 100%; max-width: none; background: none; box-shadow: none;}
body .navbar .menu-item {display: block;}

.calculatorBox.makeClaim .leftPanel {
  max-width: 100%!important;;
  padding: 20px 30px 40px 30px;
}  
.calculatorBox.makeClaim .button-div {
  flex-flow: inherit;
  gap: 20px;
  max-width: 100%;
}

.MakeClaimBox li {
  margin-left: 1rem;
}
.calculatorBox{padding: 0!important;} 
.calculatorBox .customWrapper {padding: 0!important;} 
.rightPanel img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
.leftPanel{
  padding: 10px 20px 100px 20px!important;
}

  .makeClaim .customButton{width: 45%;}
  .articleContent .text-current, .articleContent .text-black{display: flex;
    padding: 8px 16px;border-radius: 6px 6px 0px 0px;
    background: var(--Light-Gray-Light-Gray-4, #EEF0F2);color: var(--black-white-black, #000);
    font-family: 'Roboto';
    font-size: 20px;
    font-style: normal;
    margin-bottom: 0;
    font-weight: 700;
    line-height: 150%; /* 30px */}
    .article-select{
      padding: 0 !important;
      --tw-border-opacity: 8;
      border-radius: 6px 6px 0px 0px;
      border: 1px solid var(--Light-Gray-Light-Gray-4, #EEF0F2);
      background: var(--black-white-white, #FFF);
    }
    .article-select ul{
      padding: 16px 16px 16px 16px;
  }
  .article-select button[aria-expanded="true"] {
    color: var(--black-white-black, #000) !important;
}
.article-select button[aria-expanded="true"] svg path {
  fill: #000;
}
.start-invensting-section{    padding: 99px 105px!important;}
[dir="rtl"]  #life-Insurance-drop-down-section-1 .subMenuHead{    padding: 0.5rem 0rem 1rem 3rem;}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .calculatorBox.makeClaim .leftPanel, .calculatorBox .leftPanel {
    max-width: 90%;
}
.calculatorBox.makeClaim .button-div{}
.postion-line {
  margin-left: 0px;
}
[dir="rtl"] .postion-line {
  padding-right: 4px !important;
}
.speck-to-advisor {
  width: 70% !important;
  padding: 30px;
  }
.calculatorBox .leftPanel{
  width: 85%;
}
.calculatorBox .rightPanel img {
  /* min-height: 87vh; */
  position: absolute;
  right: -10rem;
  z-index: 99;
  top: 23%;
  max-width: 30%;
  border-radius: 30px !important;
  object-fit: cover;
  object-position: 35%;
  height: 70%;
}
  .widePageWrap{
    padding: 3rem 2rem !important;
  }
  .header .inner .menu .menu-item a {
    padding: 0.5rem 1rem !important;
    margin: 0;
}
[dir='rtl'] .claimLevel1 li {
  padding: 0.3rem 0rem 0.3rem 3rem;
}
[dir='rtl'] .header .inner .menu .menu-item a {
  padding: 0.5rem 0rem !important;
  margin: 0;
}
  /* .contentBox .button-div .secondary-button {
    margin-bottom: 0;
  } */
  .MakeClaimBox li {
    margin-left: 1rem;
  }
  .calculatorBox{padding: 0!important;} 
  .calculatorBox .customWrapper {padding: 0!important;} 
  .rightPanel img {
    height: 300px;
    width: 100%;
    object-fit: cover;
}
.leftPanel{
  padding: 20px 48px 100px 48px!important;
}
.articleContent .text-current, .articleContent .text-black{display: flex;
  padding: 8px 16px;border-radius: 6px 6px 0px 0px;
  background: var(--Light-Gray-Light-Gray-4, #EEF0F2);color: var(--black-white-black, #000);
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  margin-bottom: 0;
  font-weight: 700;
  line-height: 150%; /* 30px */}
  .article-select{
    padding: 0 !important;
    --tw-border-opacity: 8;
    border-radius: 6px 6px 0px 0px;
    border: 1px solid var(--Light-Gray-Light-Gray-4, #EEF0F2);
    background: var(--black-white-white, #FFF);
  }
  .article-select ul{
    padding: 16px 16px 16px 16px;
}
.article-select button[aria-expanded="true"] {
  color: var(--black-white-black, #000) !important;
}
.article-select button[aria-expanded="true"] svg path {
fill: #000;
}
[dir="rtl"]  .postion-line a{
  margin-right: 20px!important;
}
}
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (orientation: portrait)  {
    /* CSS rules specific to iPad Pro portrait orientation */
    .mainContainer .faqWrapper {
      max-width: 90%;
  }/************ faq section  ***************/
  .widePageWrap{
    padding: 3rem 2rem !important;
  }/************ wrapper width css ***************/
  .button-div {
    flex-flow: column;
    gap: 20px;
}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /************ faq section width css  ***************/
  .mainContainer .faqWrapper {
    max-width: 80%;
}
.calculatorBox .customWrapper {
  padding: 0 !important;
  position: relative;
}
body[dir="rtl"] .calculatorBox .rightPanel img {
  left: -10rem;
  right: auto;
  object-fit: cover;
  position: absolute;
  top: 6%;
}
body[dir="rtl"] .calculatorBox .customWrapper {
  padding: 0 !important;
  position: relative;
}
}

/* ipad Pro Media query */
@media only screen 
  and (min-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 4.3) {

/*iPad Navigation Menu  */
body .menuPanel ul li button {padding:0.5rem;}
/* .claim, .support, .dropdown.postion-line {display: none;} 
.plans-section .key-feature-section {padding: 2rem;} */
/* .plans-section .faqWrapper {max-width:75%;} */
body .Get-Started-with-solidatary-section, body .your-partner-banner{
  padding: 4rem 2rem;
  }
  body .testimonilas-inner-box {padding:0 2rem ;}
  body .stay-tuned-section p.description {max-width: none;}
  .makeClaim {flex-direction: column;} 
   .mainContainer .hero-banner-box { width: 40.4rem; }/********* hero banner ************/
  /********** primary secondary button css *****************/
  .widePageWrap .button-div { flex-direction: row; display: block; }
  .widePageWrap .button-div button {margin:0 0.5rem}
  .mainContainer .Get-Started-with-solidatary-section .button-div {max-width:70%;}
  body .start-invensting-section .button-div { max-width: 25rem; }
  .mainContainer .Get-Started-with-solidatary-section .button-div { max-width: 90%!important; }
  /********** primary secondary button css *****************/

  /********** calculator page css *****************/
  .calculatorBox form input,  .calculatorBox form select{ height: 50px; }
  .calculatorBox form  select { -webkit-appearance: none; /* For older Safari */ -moz-appearance: none; /* For older Firefox */ background-image: url('../src/assets/images/Chevronsmalldown.svg'); /* Custom arrow icon */ background-repeat: no-repeat; background-position: 95% 48%; }
  /********** calculator page css *****************/

  /************* footer-section ****************/
  .logo-footer-box{
    margin-bottom: 30px;
}
 .apple-logo-box{
    margin-bottom: 20px;
}
.footer-section{
  padding: 48px 24px 0 24px;
}
  /************* footer-section ****************/

.widePageWrap .contentBox .left {
  display: flex;
  justify-content: start;
  flex-direction: column;
}
.calculatorBox.makeClaim .leftPanel {
  max-width: 90%;
  padding: 20px 30px 40px 30px;
}
}


.stay-tuned-section .buttonSet {justify-content: center; display: flex;}
.stay-tuned-section .buttonSet button {max-width:190px;}
.articleDetails .topBanner, .articleDetails .articleContent {padding:0 2rem}
.articleDetails .breadCrumb {padding:0 2rem}

.form-input input:hover {
  border: 1px solid var(--Mid-Gray-Mid-Gray-3, #84919A);
  background: var(--black-white-white, #FFF);
}

.border-default{
  border: 1px solid var(--Mid-Gray-Mid-Gray-5, #B0BABF);
  background: var(--black-white-white, #FFF);
}

.border-active{
  border-color: var(--Dark-Gray-Dark-Gray-1, #1A2024);
  background: var(--black-white-white, #FFF);
}

button:disabled {
  cursor: default;
  opacity: 0.5;
}

.grecaptcha-badge{
  position: relative;
  z-index: 99999 !important;
}